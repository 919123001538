<ng-container *ngIf="user$ | async">
  <div class="w-full flex justify-center py-2" *ngIf="!isProfileComplete">
    <div class="bg-stone-100 p-2 px-4 rounded-md">
      <span class="text-gray-600"> 👉 Please complete your profile so we can validate future submissions</span>
      <a class="ml-4 font-semibold underline underline-offset-4 hover:underline-offset-2"
        routerLink="/settings/profile">Complete profile</a>
    </div>
  </div>
</ng-container>
<div class="w-full border-b border-gray-200" [ngClass]="{
  '!bg-black border-black font-fast50': challengesService.config && challengesService.config.header === 'deloitte'
}" *ngIf="{
  isUserAdmin: authService.isUserAdmin$ | async,
  user: user$ | async,
  profile: profile$ | async,
  isBurgerMenuOpen: burgerMenu.currentState$ | async
} as states">
  <nav class="md:w-11/12 2xl:max-w-9/12 mx-auto flex items-center justify-between flex-wrap h-18 z-30">
    <div class="cursor-pointer flex items-center ml-4 md:ml-0  divide-x divide-gray-500">
      <a href="https://app.ekipa.de"
        *ngIf="!(challengesService.config && challengesService.config.header === 'deloitte')">
        <img class="h-8 block" alt="ekipa Logo" src="/assets/images/logo.png" />
      </a>
      <ng-container *ngIf="challengesService.config && challengesService.config.header === 'deloitte'">
        <a href="http://deloitte.de" target="_blank">
          <img class="h-8 block" alt="Deloitte Fast 50 Logo"
            src="	https://www2.deloitte.com/content/dam/assets/logos/deloitte.svg" />
        </a>
        <a class="ml-4 pl-4 items-center hidden sm:flex" href="https://app.ekipa.de">
          <span class="text-gray-300 text-sm font-light mr-2">Powered by ekipa</span>
          <img class=" h-8 block" alt="ekipa Logo" src="/assets/images/logo_white.png" />
        </a>

      </ng-container>
      <a *ngIf="!(challengesService.config && challengesService.config.header === 'deloitte')"
        class="font-semibold py-1 px-3 rounded-3xl ring-gray-600 ring-1 ring-inset mx-2 text-gray-600 flex items-center hover:bg-slate-100"
        href="https://ekipa.de/en/companies/" target="_blank">
        For corporates
      </a>
    </div>
    <div *ngIf="states.isUserAdmin"
      class="border-gray-500 hover:bg-gray-200 text-gray-800 ml-3 text-sm font-medium inline-flex items-center justify-center p-2 rounded-sm cursor-pointer"
      [routerLink]="['/admin']" routerLinkActive="bg-gray-200">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor"
        stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
      </svg>
      Admin
    </div>
    <ekipa-header-nav-items class="w-full hidden flex-grow lg:flex lg:items-center lg:w-auto justify-end my-2"
      [user]="states.user" [profile]="states.profile">
    </ekipa-header-nav-items>
    <div class="block lg:hidden mr-4">
      <button aria-label="Burger Menu" id="burgerMenu"
        class="flex lg:hidden items-center px-3 py-2 my-4 border rounded text-black-200 hover:border-gray-500"
        [ngClass]="{
          'border-gray-800 shadow-md': states.isBurgerMenuOpen
        }" (click)="burgerMenu.toggle()">
        <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </button>
    </div>

  </nav>

  <div class="mt-16 mb-8 flex flex-col items-center lg:hidden" *ngIf="states.isBurgerMenuOpen">
    <ekipa-header-nav-items class="flex flex-col gap-4" [user]="states.user" [profile]="states.profile">
    </ekipa-header-nav-items>
  </div>
</div>