<ng-container *ngIf="user; else notSignedIn">
  <ng-container *ngTemplateOutlet="dashboard"></ng-container>
  <ng-container *ngTemplateOutlet="permanentItems"></ng-container>
  <div class="lg:flex items-center">
    <ng-container *ngTemplateOutlet="profileDropdown"></ng-container>
  </div>
</ng-container>

<!-- <ekipa-change-language
  class="hidden lg:flex items-center mr-4"
></ekipa-change-language> -->

<ng-template #notSignedIn>
  <ng-container *ngTemplateOutlet="permanentItems"></ng-container>
  <div class="my-2">
    <button class="mr-6 btn btn-secondary" (click)="authDialogService.open(AuthDialogState.Login)">
      {{'headerBar.login' | transloco}}
    </button>
  </div>
  <div class="my-2">
    <button class="mr-6 shadow-none btn btn-outline-primary"
      (click)="authDialogService.open(AuthDialogState.Register, challengesService.isFast50 ? RegistrationState.CompanyInformation : null )">
      {{'headerBar.register' | transloco}}
    </button>
  </div>
</ng-template>

<ng-template #profileDropdown>
  <div class="relative">
    <div class="flex items-center cursor-pointer" (click)="showDropdown = !showDropdown" *ngIf="profile">
      <ekipa-avatar [name]='profile.display_name' [size]="'sm'" [src]="profile.photo_url"
        [showTooltip]="false"></ekipa-avatar>
      <svg class="h-5 w-5 text-gray-800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd" />
      </svg>
    </div>
    <ng-container *ngIf="showDropdown">
      <div class="overflow-visible fixed inset-0 h-full w-full z-auto" (click)="showDropdown = !showDropdown"></div>
      <ekipa-header-profile-dropdown (click)="showDropdown = !showDropdown"
        [profile]="profile"></ekipa-header-profile-dropdown>
    </ng-container>
  </div>
</ng-template>
<ng-template #dashboard>
  <div>
    <a [routerLink]="['/dashboard']" routerLinkActive="text-primary"
      class="block lg:inline-block text-black-200 font-bold mr-6 text-md" [ngClass]="{
        '!text-white': (challengesService.config && challengesService.config.header === 'deloitte')
      }">
      Dashboard
    </a>
  </div>
</ng-template>

<ng-template #permanentItems>
  <div *ngIf="!(challengesService.config && challengesService.config.header === 'deloitte')" class="relative">
    <button (click)="showChallengeDropdown = !showChallengeDropdown"
      class=" text-black-200 font-bold mr-6 text-md flex items-center">
      Challenges
      <svg class="h-5 w-5 text-gray-800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd" />
      </svg>
    </button>
    <ng-container *ngIf="showChallengeDropdown">
      <div class="overflow-visible fixed inset-0 h-full w-full z-auto"
        (click)="showChallengeDropdown = !showChallengeDropdown"></div>
      <ng-container *ngIf="programsOverview$ | async as programsOverview">
        <ekipa-header-challenge-dropdown [programsOverview]="programsOverview"
          (click)="showChallengeDropdown = !showChallengeDropdown" />
      </ng-container>
    </ng-container>
  </div>
  <a *ngIf="!(challengesService.config && challengesService.config.header === 'deloitte')" [routerLink]="['/faq']"
    routerLinkActive="text-primary" class="block lg:inline-block text-black-200 font-bold mr-6 text-md">
    FAQ
  </a>
</ng-template>