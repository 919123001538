import { Component, Input, OnInit, inject } from '@angular/core';
import { AuthDialogService, AuthDialogState, AuthService, ChallengesService, IProfile, ProgramService, RegistrationState } from '@ekipa/common-ui';
import { Observable } from 'rxjs';

@Component({
  selector: 'ekipa-header-nav-items',
  templateUrl: './header-nav-items.component.html',
})
export class HeaderNavItemsComponent implements OnInit {
  AuthDialogState = AuthDialogState;
  RegistrationState = RegistrationState;
  showDropdown = false;
  showChallengeDropdown = false;
  programsOverview$: Observable<any>;
  @Input() user: any;
  @Input() profile: IProfile;
  challengesService = inject(ChallengesService);
  constructor(
    public authDialogService: AuthDialogService,
    public authService: AuthService,
    private programService: ProgramService
  ) { }

  ngOnInit(): void {
    this.programsOverview$ = this.programService.getProgramsOverview();
  }
}
