<div class="right-[1rem] top-[2rem] dropdown-wrapper divide-y">
  <div>
    <a routerLink="/challenges"
      class="flex cursor-pointer px-4 py-2 text-sm capitalize text-gray-700 hover:bg-gray-100 hover:rounded-md mx-2 mb-2">
      All Challenges
    </a>
  </div>
  <div class="pt-2">
    <div class="text-xs text-gray-400 px-4 mx-2 mb-2 uppercase">By Program</div>
    <ng-container *ngFor="let program of programs">
      <a routerLink="{{program.routerLink}}" class=" flex cursor-pointer px-4 py-2 text-sm capitalize text-gray-700 hover:bg-gray-100 hover:rounded-md mx-2
        gap-2">
        {{program.title}}
        <span *ngIf="program.isOngoing"
          class="ml-2 rounded-md bg-[#adfa1d] px-1.5 py-0.5 text-xs leading-none text-[#000000] no-underline group-hover:no-underline">Ongoing</span>
      </a>
    </ng-container>
  </div>
</div>