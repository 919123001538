import { Component, Input } from '@angular/core';
import { AuthService, IProfile } from '@ekipa/common-ui';

@Component({
  selector: 'ekipa-header-profile-dropdown',
  templateUrl: './header-profile-dropdown.component.html'
})
export class HeaderProfileDropdownComponent {

  @Input() profile: IProfile;

  constructor(public authService: AuthService) { }


}
