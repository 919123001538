import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PipesModule } from '@ekipa/common-ui';


type DropdownItem = {
  title: string;
  isOngoing?: boolean;
  routerLink: string;
}

const items: DropdownItem[] = [
  {
    title: 'Future Makers',
    isOngoing: true,
    routerLink: '/programs/future-makers'
  },
  {
    title: "Deloitte's Quantum Climate Challenge 2024",
    isOngoing: true,
    routerLink: '/challenges/deloitte-quantum-24'
  },
  {
    title: 'Finance & Insurance',
    routerLink: '/program/finance',
    isOngoing: true
  },
  {
    title: 'HealthTech',
    routerLink: '/program/healthtech'
  },
  {
    title: 'AI & Data Science',
    routerLink: '/program/ai-data'
  },
];


@Component({
  selector: 'ekipa-header-challenge-dropdown',
  templateUrl: './header-challenge-dropdown.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    PipesModule
  ]
})

export class HeaderChallengeDropdownComponent implements OnInit {
  @Input() programsOverview: any;
  programs: DropdownItem[];

  ngOnInit(): void {
    this.programs = [...items];
    this.programsOverview.map(obj => obj.node).map((program: any) => {
      if (program.is_listed) {
        const item: DropdownItem = {
          title: program.title[0].text,
          isOngoing: true,
          routerLink: `/program/${program._meta.uid}`
        };
        this.programs.unshift(item);
      }
    });
  }

}
