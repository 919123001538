<div class="right-0 dropdown-wrapper">
  <a
    [routerLink]="['/settings/profile']"
    class="flex cursor-pointer px-4 py-2 text-sm capitalize text-gray-700 hover:bg-gray-200 hover:rounded-md mx-2 gap-2"
  >

    <ekipa-avatar [name]='profile.display_name' [size]="'sm'" [src]="profile.photo_url" [showTooltip]="false"></ekipa-avatar>

    <div class="">
      <p class="font-medium text-md text-gray-800">{{profile.display_name}}</p>
      <span class="font-light text-gray-800">{{profile.type === 'startup' ? 'Startup' : 'Innovator'}}</span>

    </div>
  </a>
  <a
    [routerLink]="['/settings/account']"
    class="flex cursor-pointer px-4 py-2 text-sm capitalize text-gray-700 hover:bg-gray-200 hover:rounded-md mx-2 items-center"
  >
    <svg
      class="fill-current"
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <rect fill="none" height="24" width="24" />
      <g>
        <path
          d="M17.41,6.59L15,5.5l2.41-1.09L18.5,2l1.09,2.41L22,5.5l-2.41,1.09L18.5,9L17.41,6.59z M21.28,12.72L20.5,11l-0.78,1.72 L18,13.5l1.72,0.78L20.5,16l0.78-1.72L23,13.5L21.28,12.72z M16.24,14.37l1.94,1.47l-2.5,4.33l-2.24-0.94 c-0.2,0.13-0.42,0.26-0.64,0.37L12.5,22h-5l-0.3-2.41c-0.22-0.11-0.43-0.23-0.64-0.37l-2.24,0.94l-2.5-4.33l1.94-1.47 C3.75,14.25,3.75,14.12,3.75,14s0-0.25,0.01-0.37l-1.94-1.47l2.5-4.33l2.24,0.94c0.2-0.13,0.42-0.26,0.64-0.37L7.5,6h5l0.3,2.41 c0.22,0.11,0.43,0.23,0.64,0.37l2.24-0.94l2.5,4.33l-1.94,1.47c0.01,0.12,0.01,0.24,0.01,0.37S16.25,14.25,16.24,14.37z M13,14 c0-1.66-1.34-3-3-3s-3,1.34-3,3s1.34,3,3,3S13,15.66,13,14z"
        />
      </g>
    </svg>
    &nbsp; Settings
  </a>
  <a
    (click)="authService.signOut()"
    class="flex cursor-pointer px-4 py-2 text-sm capitalize text-gray-700 hover:bg-gray-200 hover:rounded-md mx-2 items-center"
  >
    <svg
      class="fill-current"
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"
      />
    </svg>
    &nbsp; Sign Out
  </a>
</div>
