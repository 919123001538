import { Component, OnInit, inject } from '@angular/core';
import { User } from '@angular/fire/auth';
import { AuthService, BurgerMenuService, ChallengesService, IProfile, ProfileService } from '@ekipa/common-ui';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'ekipa-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  profile$: Observable<IProfile>;
  user$: Observable<User>;
  challengesService = inject(ChallengesService);
  profileService = inject(ProfileService);
  isProfileComplete: boolean;

  constructor(
    public authService: AuthService,
    public burgerMenu: BurgerMenuService,
  ) {
  }

  ngOnInit(): void {
    this.profile$ = this.profileService.profile$.pipe(
      tap(profile => {
        if (profile) {
          this.isProfileComplete = Object.keys(profile).length && this.profileService.isProfileComplete(profile);
        }
      })
    );
    this.user$ = this.authService.authState$;
  }

}
